<template>
    <div class="w-full h-full flex flex-1 flex-col">
        <CustomForm
            ref="form"
            class="flex flex-col h-full"
            :initial-values="initialValues"
            :submit="handleSubmit"
        >
            <template v-slot="props">
                <Page
                    ref="page"
                    title="Rental Application"
                    :props="props"
                    :loading="loading"
                >
                    <template v-slot:header-addition>
                        <div class="w-full bg-white flex flex-col flex-1 p-4 my-4">
                            <h2 class="font-bold text-start my-4 text-xl">Pre-evaluation information</h2>
                            <h4 class="font-bold text-gray-400 text-start text-sm">
                                Privacy notice. We will ask for aditional information in order to proceed with identity verification and
                                background screening processes.
                            </h4>
                        </div>
                    </template>
                    <h2 class="font-bold text-gray-400 text-start mb-4 ml-4 text-base">Additional information</h2>
                    <div class="w-4/5 px-4 grid grid-cols-2 gap-4">
                        <div>
                            <SelectField
                                name="employed_status"
                                label="Employed Status"
                                trackBy="key"
                                :options="employment_statuses"
                                :validate="composeValidators(required)"
                            />
                        </div>
                    </div>
                    <div class="w-40 flex px-6 md:px-4 py-6 mb-24 fixed bottom-1">
                        <button
                            type="submit"
                            class="flex-1 btn"
                        >
                            start identity verification process
                        </button>
                    </div>
                    <div class="flex-col flex-1 flex fixed bottom-0 w-full bg-color-status py-4 px-4 mt-2 justify-between">
                        <h2 class="w-full bg-color-status font-bold text-start my-4 text-base">
                            Application status:
                            <span class="turn-text-to-blue">Background Screening</span>
                        </h2>
                    </div>
                </Page>
            </template>
        </CustomForm>
    </div>
</template>

<script type="text/javascript">
import Page from '@/components/ui/Page';
import NotifyMixin from '@/mixins/NotifyMixin';
import SelectField from '@/components/form/SelectField';
import CustomForm from '@/components/form/Form';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'PreEvaluationForm',
    components: { Page, SelectField, CustomForm },
    mixins: [NotifyMixin, ValidatorMixin],

    data() {
        return {
            initialValues: {
                employed_status: null,
                deposit_amount: 0,
            },

            employment_statuses: [
                {
                    value: 'Employed',
                    key: '1',
                },
                {
                    value: 'Student',
                    key: '2',
                },
                {
                    value: 'Retired',
                    key: '3',
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
        }),

        propertyId() {
            return this.$route.params.propertyId || null;
        },

        role() {
            return this.currentProfile.type || null;
        },
    },

    methods: {
        ...mapActions({
            setBackgroundScreening: 'application/setBackgroundScreening',
        }),

        handleSubmit(values) {
            this.setBackgroundScreening({
                employed_status: Number(values.employed_status),
                deposit_amount: Number(values.deposit_amount),
            });
            this.$router.push({
                name: 'application_service.background_screening',
                params: { propertyId: this.propertyId },
            });
        },
    },
};
</script>
